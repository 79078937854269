<template>
	<v-app id="pui9app">
		<v-container v-if="passwordReset" fill-height>
			<v-layout column>
				<v-flex xs12>
					<v-layout align-center justify-center column fill-height>
						<v-icon x-large color="success">far fa-check-circle</v-icon>
						<h1>{{ $t('newpassword.ok.message') }}</h1>
						<br />
						<v-btn color="primary" style="color: #fff !important" @keyup.enter.native="redirectLogin" @click.native="redirectLogin">
							{{ $t('newpassword.ok.btn') }}
						</v-btn>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-container>
		<!--Mobile case-->
		<v-container v-else-if="isMobile" class="ma-0 pa-0">
			<v-layout wrap>
				<v-flex xs12>
					<v-card-text class="pl-0 pr-0 pb-1">
						<div class="login__logo"></div>
						<h2 class="headline mt-2 mb-2 login__title login__title--mobile">{{ $t('newpassword.title') }}</h2>
						<v-form>
							<div class="login__input-label--mobile pl-3">{{ $t('newpassword.password') }}</div>
							<v-text-field
								v-model="model.newpassword"
								name="newpassword"
								id="newpassword"
								class="pt-0 mobile"
								maxlength="100"
								:append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
								@click:append="showPassword = !showPassword"
								:type="showPassword ? 'text' : 'password'"
							></v-text-field>
							<div class="login__input-label--mobile pl-3">{{ $t('newpassword.repeatpassword') }}</div>
							<v-text-field
								v-model="model.repeatpassword"
								name="repeatpassword"
								id="repeatpassword"
								class="pt-0 mobile"
								maxlength="100"
								:append-icon="showRepeatPassword ? 'far fa-eye' : 'far fa-eye-slash'"
								@click:append="showRepeatPassword = !showRepeatPassword"
								:type="showRepeatPassword ? 'text' : 'password'"
							></v-text-field>
						</v-form>
					</v-card-text>
					<v-card-actions class="pl-3 pr-3 pt-0 pb-0">
						<v-btn block color="secondary" depressed class="elevation-0" @click.native="resetPassword" :disabled="!validPasswords">
							{{ $t('newpassword.save') }}
						</v-btn>
					</v-card-actions>
				</v-flex>
			</v-layout>
		</v-container>
		<!--No Mobile case-->
		<v-container v-else class="ma-0 pa-0" fill-height>
			<v-layout wrap align-center>
				<v-flex xs3 md3></v-flex>
				<v-flex xs6 md6>
					<v-card-text class="pl-20 pr-20 pb-1">
						<v-layout wrap align-center justify-center>
							<div class="login__logo"></div>
						</v-layout>

						<v-layout wrap align-center justify-center>
							<h2 class="headline mt-2 mb-2 login__title login__title--desktop">{{ $t('newpassword.title') }}</h2>
						</v-layout>

						<v-layout>
							<v-flex xs2 md2></v-flex>
							<v-flex xs8 md8>
								<v-form class="pui-form">
									<v-layout>
										<v-flex xs12>
											<div class="login__inputLabel--desktop">{{ $t('newpassword.password') }}</div>
											<v-text-field
												solo
												flat
												outlined
												class="desktop"
												maxlength="100"
												:append-icon="showPassword ? 'far fa-eye' : 'far fa-eye-slash'"
												@click:append="showPassword = !showPassword"
												:type="showPassword ? 'text' : 'password'"
												name="newpassword"
												id="newpassword"
												v-model="model.newpassword"
											></v-text-field>
										</v-flex>
									</v-layout>
									<v-layout>
										<v-flex xs12>
											<div class="login__inputLabel--desktop">{{ $t('newpassword.repeatpassword') }}</div>
											<v-text-field
												solo
												flat
												outlined
												class="desktop"
												maxlength="100"
												:append-icon="showRepeatPassword ? 'far fa-eye' : 'far fa-eye-slash'"
												@click:append="showRepeatPassword = !showRepeatPassword"
												:type="showRepeatPassword ? 'text' : 'password'"
												name="repeatpassword"
												id="repeatpassword"
												v-model="model.repeatpassword"
											></v-text-field>
										</v-flex>
									</v-layout>
								</v-form>
							</v-flex>
							<v-flex xs2 md2></v-flex>
						</v-layout>
					</v-card-text>

					<v-card-actions class="pl-20 pr-20 pb-1">
						<v-spacer></v-spacer>
						<v-btn
							width="300"
							color="primary"
							@keyup.enter.native="resetPassword"
							@click.native="resetPassword"
							:disabled="!validPasswords"
						>
							{{ $t('newpassword.save') }}
						</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-flex>
				<v-flex xs3 md3></v-flex>
			</v-layout>
		</v-container>
	</v-app>
</template>
<script>
export default {
	name: 'PuiUserResetPassword',
	data() {
		return {
			isMobile: false,
			doSetPasswordUrl: '/gestaut/setNewPassword',
			showPassword: false,
			showRepeatPassword: false,
			model: {
				newpassword: null,
				repeatpassword: null
			},
			passwordReset: false,
			fallbackLanguage: 'es',
			passwordpattern: null
		};
	},
	computed: {
		validPasswords() {
			return this.model.newpassword && this.model.repeatpassword && this.model.newpassword === this.model.repeatpassword;
		},
		lang() {
			return this.$route.query.lang || this.fallbackLanguage;
		}
	},
	created() {
		this.isMobile = this.$store.getters.isMobile;
		this.getPasswordPattern();
	},
	methods: {
		getPasswordPattern() {
			this.$puiRequests.getRequest('/gestaut/getPasswordPattern', null, (data) => {
				if (data.data != null) {
					this.passwordpattern = new RegExp(data.data);
				}
			});
		},
		resetPassword() {
			if (this.passwordpattern !== null) {
				const isValid = this.passwordpattern.test(this.model.newpassword);

				if (!isValid) {
					this.$puiNotify.error(this.$puiI18n.t('newpassword.passwrongformat'));
					return;
				}
			}
			const params = {
				token: this.getUrlParam('token'),
				newPassword: this.model.newpassword
			};
			this.$puiRequests.getRequest(
				this.doSetPasswordUrl,
				params,
				() => {
					this.passwordReset = true;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error });
					this.passwordReset = false;
				}
			);
		},
		getUrlParam(parameter) {
			const token = new window.URL(window.location.href).searchParams.get(parameter);
			if (token) {
				return token;
			}
			let urlparameter = null;
			if (window.location.href.indexOf(parameter) > -1) {
				urlparameter = this.getUrlVars()[parameter];
			}
			return urlparameter;
		},
		getUrlVars() {
			const vars = {};
			window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
				vars[key] = value;
			});
			return vars;
		},
		redirectLogin() {
			window.location.href = window.location.origin + window.location.pathname.replace('/nswnewuserpass', '');
		}
	},
	watch: {
		lang: {
			immediate: true,
			handler(value) {
				this.$puiEvents.$emit('onPuiChangedLang', value);
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
.login {
	&__title {
		text-align: center;
		&--desktop {
			font-weight: 500;
		}
	}
	&__form {
		&--mobile {
			padding-top: 0px;
		}
	}
	&__inputLabel {
		&--desktop {
			padding-bottom: 10px;
		}
		&--mobile {
			padding-top: 10px;
		}
	}
	&__inputField {
		&--mobile > .v-input__control > .v-input__slot {
			border: 0px solid red !important;
			padding-left: 15px !important;
			padding-right: 15px !important;
		}
	}
}
</style>
